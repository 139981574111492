import axios from 'axios';
import config from '../helpers/config'

const hentListe = async (name, setListe, token, setError, setErrorMessage) => {
    let res;
    const hent = await axios({
        url: config.baseURL + 'hentliste/' + name,
        headers: {
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + token
        }
    }).catch(function (error) {
        if (error.response) {
            res = error.response.data;
            setErrorMessage(res)
            setError(true)
            return res;
        } else if (error.request) {
            res = error.request
            setError(true)
            setErrorMessage(res)
            return res
        } else {
            res = error.message
            setError(true)
            setErrorMessage(res)
            return res
        }
    })
    console.log(hent.data)
    setListe(hent.data)
    return hent.data
}

export default hentListe;