import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { purple } from '@mui/material/colors';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  '&:hover': {
    backgroundColor: purple[700],
  },
}));

/*const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: purple[500],
  },
}));*/


export default function CustomizedButtons({ label, disabled, type, onClick }) {
  return (
    <div>
      <ColorButton variant="contained" type={type} color="primary" disabled={disabled} onClick={onClick}>
        {label}
      </ColorButton>
    </div>
  );
}