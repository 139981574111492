import React, { useEffect, useState } from 'react';
import { hentListe, slet } from '../../services';
import Title from '../../components/title';
import { Box } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EditPost from '../../components/nyregnskab';
import { css } from "@emotion/react";
import { GrEdit } from "react-icons/gr";
import useModal from '../../components/useModal';
import { IconContext } from "react-icons";
import ButtonIcon from '../../components/ButtonIcon';
import PacmanLoader from 'react-spinners/PacmanLoader'

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Regnskab = ({ token }) => {
    const [liste, setListe] = useState([])
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [open1, setOpen1] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open13, setOpen13] = useState(false);
    const [open19, setOpen19] = useState(false);
    const [open28, setOpen28] = useState(false);
    const [open34, setOpen34] = useState(false);
    const { isShowing, open, close, selectedID, selectedNavn, selectedValue, selectedTilID } = useModal();


    useEffect(() => {
        let cancel = false;
        setLoading(true)
        const getList = async () => {
            if (cancel) return;
            await hentListe('regnskab', setListe, token, setError, setErrorMessage)
            setLoading(false)
        }
        getList()
        return () => {
            cancel = true;
        }
    }, [status, token])

    const handleClick = (id) => {
        switch (id) {
            case 1: setOpen1(!open1)
                break
            case 4: setOpen4(!open4)
                break
            case 13: setOpen13(!open13)
                break
            case 19: setOpen19(!open19)
                break
            case 28: setOpen28(!open28)
                break
            case 34: setOpen34(!open34)
                break
            default: setErrorMessage('error')
        }
    };

    const handleSlet = async (id) => {
        await slet(id, token, setError, setErrorMessage, 'fjernregnskab/')
        setStatus(!status)
    }

    const handleOpen = (id) => {
        switch (id) {
            case 1: return open1
            case 4: return open4
            case 13: return open13
            case 19: return open19
            case 28: return open28
            case 34: return open34
            default: setErrorMessage('error')
        }
    };

    const subId = (tal) => {
        switch (tal) {
            case 1: return 3
            case 4: return 12
            case 13: return 18
            case 19: return 27
            case 28: return 33
            case 34: return 37
            case 35: return 35
            case 36: return 36
            default: setErrorMessage('error')
        }
    }

    const calc = (tal, diff) => {
        let facit = 0
        function sum(input) {

            if (toString.call(input) !== "[object Array]")
                return false;

            var total = 0;
            for (var i = 0; i < input.length; i++) {
                if (isNaN(input[i])) {
                    continue;
                }
                total += Number(input[i]);
            }
            return total;
        }

        const y = liste?.[subId(tal)]?.beloeb;

        facit = sum([y, diff]);
        return facit;
    }

    const tilbage = (value, text) => {
        const tilbage = liste?.[36]?.beloeb;
        const start = liste?.[0]?.beloeb;
        const sum = text === 'ialt' ? start - value : value + tilbage;
        return sum
    }
    //const tilbageIalt = [35, 36]
    const Retur = ({ id }) => {
        console.log('retur ' + id)
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <List
                    sx={{ width: "100%", maxWidth: 500, bgcolor: "transparent", color: 'white' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    {liste?.filter(l => l.sum).map((t) => {
                        console.log('retur loop ' + t.id)
                        return (<Box
                            key={t.id}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <ListItemButton>
                                <ListItemText primary={liste?.[t]?.navn} />
                                {liste?.[t]?.beloeb}
                            </ListItemButton>
                        </Box>
                        )
                    })}

                </List>
            </Box>
        )
    }


    const ListItems = ({ id }) => {
        console.log(id)
        if (error) {
            return <Box>{errorMessage}</Box>
        } else {
            return (
                <List
                    sx={{ width: "100%", maxWidth: 500, bgcolor: "transparent", color: 'white' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"

                >
                    <ListItemButton onClick={() => handleClick(id)}>
                        <ListItemText primary={liste?.[id]?.navn} />
                        {handleOpen(id) ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>

                    <Collapse in={handleOpen(id)} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {liste
                                .filter((l) => l.tilhors_id === id)
                                .map((l) => {
                                    console.log('tilhørs loop' + l)
                                    return (
                                        <ListItemButton
                                            key={l.id}
                                            sx={{ pl: 4 }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <div>
                                                        {l.navn}
                                                    </div>
                                                }
                                            />{l.beloeb}
                                            <ButtonIcon type='button' onClick={() =>
                                                open(l.id, l.navn, l.beloeb, l.tilhors_id)
                                            }><IconContext.Provider value={{ style: { color: "blue" } }}>
                                                    <GrEdit />
                                                </IconContext.Provider></ButtonIcon>
                                            <Button type='button' onClick={() => handleSlet(l.id)}>X</Button>
                                        </ListItemButton>
                                    );
                                })}
                        </List>
                    </Collapse>
                    {liste.filter((k) => k.subtotal === id)
                        .map((k) => {
                            return (
                                <ListItemButton key={k.id}>
                                    <ListItemText primary="Subtotal" />
                                    {k.beloeb}
                                </ListItemButton>)
                        })}

                </List>
            );
        }
    };

    return (
        <div>
            {loading ? <div>Loading....<PacmanLoader color='blue' css={override} loading={loading} size={150} /></div> : <React.Fragment>
                <Title label='Regnskab' font='Fredericka the Great' size={30} color='blue' />
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <h2>{liste?.[0]?.navn} {liste?.[0]?.beloeb}</h2><ButtonIcon type='button' onClick={() => open(0, liste?.[0]?.navn, liste?.[0]?.beloeb, null)}>
                        <IconContext.Provider value={{ style: { color: "blue" } }}>
                            <GrEdit />
                        </IconContext.Provider>
                    </ButtonIcon>
                </Box>
                {liste?.filter(l => l.title).map((t) => (
                    <Box
                        key={t.id}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        {console.log('titel ' + t.id)}
                        <ListItems id={t.id} />
                    </Box>
                ))}

                <Retur />


                <EditPost
                    id={selectedID}
                    navn={selectedNavn}
                    beloeb={selectedValue}
                    open={isShowing}
                    handleOpen={close}
                    token={token}
                    status={status}
                    setStatus={setStatus}
                    subtotal={calc}
                    subId={subId}
                    tilbage={tilbage}
                    tilId={selectedTilID}
                />
            </React.Fragment>}
        </div>
    );

}

export default Regnskab;