import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";

export default function Text({ errors, label, type, defaultV, err, onChange, icon }) {

  return (
    <FormControl sx={{ m: 1, width: "30ch" }} variant="outlined">
      <InputLabel>{err ? errors.message : label}</InputLabel>
      <OutlinedInput
        error={err}
        type={type}
        defaultValue={defaultV}
        onChange={(e) => onChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" edge="end">
              {icon}
            </IconButton>
          </InputAdornment>
        }
        sx={{
          borderColor: "purple",
          borderRadius: 10,
          borderWidth: 2,
          "&:hover $notchedOutline": {
            borderColor: "yellow"
          },
          "&$focused $notchedOutline": {
            borderColor: "green"
          },
          focused: {},
          notchedOutline: {}
        }}
        label={err ? errors.message : label}
      />
    </FormControl>
  );
}