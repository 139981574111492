import React, { useEffect, useState } from 'react';
import { hentListe } from '../../services';
import { Box, Typography } from '@mui/material';
import Selector from '../../components/Select';
import Opret from '../../components/OpretTabel';

const Desktop = ({ token }) => {
  const [liste, setListe] = useState([])
  const [status, setStatus] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [length, setLength] = useState(0)
  const [nyListe, setNyListe] = useState('')
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(!openModal);

  useEffect(() => {
    let cancel = false;
    setLoading(true)
    const getList = async () => {
      if (cancel) return;
      await hentListe('tablenames', setListe, token, setError, setErrorMessage)
      setLoading(false)
      setLength(liste?.length === undefined ? 0 : liste?.length)
    }
    getList()
    return () => {
      cancel = true;
    }
  }, [status, token, liste])

  const onChange = async e => {
    await hentListe(e, setNyListe, token, setError, setErrorMessage)
  }

  const ListDisplay = () => {
    return (
      <Selector label='vælg liste her' liste={liste} onChange={onChange} />
    )
  }

  const NyListeDisplay = () => {
    return (
      <Box>
        <Typography textAlign='center'>Tryk her for at oprette en ny liste <Opret token={token} open={openModal} handleOpen={handleOpen} length={length} /></Typography>
      </Box>
    )
  }

  const IngenListe = () => {
    return (
      <Box>
        <Typography textAlign='center'>Der er ikke nogen lister endnu, opret en her <Opret token={token} open={openModal} handleOpen={handleOpen} length={length} /></Typography>
      </Box>
    )
  }

  return (
    <Box>
      {errorMessage === 'Not found' ? <IngenListe /> : <ListDisplay />}
    </Box>
  );
}


export default Desktop;