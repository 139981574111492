import React, { useEffect, useRef, useState } from 'react';
import { redigerListe } from '../services';
import { useForm, Controller } from 'react-hook-form';
import Text from '../components/InputTextField';
import CustomizedButtons from '../components/ButtonNoBackdrop';
import Modal from '../components/modal';
import { Box, Chip } from '@mui/material';


export default function EditPost({ open, handleOpen, id, navn, beloeb, token, status, setStatus, subtotal, subId, tilbage, tilId }) {
    const [isLoading, setIsLoading] = useState(false);
    const idRef = useRef(id)
    const tilIdRef = useRef(tilId)
    const beloebRef = useRef(beloeb)
    const [isId, setIsId] = useState(0);
    const [tilIsId, setTilIsId] = useState(0);
    const [isBeloeb, setisBeloeb] = useState(0);
    const [message, setMessage] = useState(false);
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState()

    const { handleSubmit, control } = useForm({
        beloeb: beloebRef
    });

    useEffect(() => {
        setIsId(id)
        setTilIsId(tilId)
        setisBeloeb(beloeb)
    }, [id, tilId, beloeb])

    useEffect(() => { console.log(id, tilId, beloeb) }, [id, tilId, beloeb])

    useEffect(() => {
        idRef.current = id
        //console.log(idRef.current, 'testing')
    }, [id])

    useEffect(() => {
        tilIdRef.current = tilId
        //console.log(tilIdRef.current, 'testing 2')
    }, [tilId])

    useEffect(() => {
        beloebRef.current = beloeb
        //console.log(beloebRef.current, 'testing 3')
    }, [beloeb])

    const onSubmit = async (data) => {
        setIsLoading(true)
        const diff = data.beloeb - isBeloeb
        var sum = null

        const ialt = subtotal(35, diff)
        var subTotalId = null
        var tilbageB = null
        var updatedSum = null

        const updatedIalt = { ...data, beloeb: ialt }
        if (tilIsId === null) {
            tilbageB = tilbage(diff, 'tilbage')
        } else {
            sum = subtotal(tilIsId, diff);
            subTotalId = subId(tilIsId)
            tilbageB = tilbage(sum, 'ialt')
            updatedSum = { ...data, beloeb: sum }
            await redigerListe(updatedSum, subTotalId, token, setError, setErrorMessage)
        }
        const updatedTilbage = { ...data, beloeb: tilbageB }
        //console.log('onsubmit', isId, subTotalId, updatedTilbage, updatedSum, tilIsId, updatedIalt)
        await redigerListe(data, isId, token, setError, setErrorMessage)
        await redigerListe(updatedTilbage, 36, token, setError, setErrorMessage)
        await redigerListe(updatedIalt, 35, token, setError, setErrorMessage)
        //console.log(r)
        setStatus(!status)
        setIsLoading(false)
        setMessage('done')
    }
    console.log(isId, 'test 1', id)
    return (

        <React.Fragment>
            <Modal titel='Rediger' open={open} handleOpen={handleOpen} >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb3">
                            <Chip label={navn} color='info' />
                            <Controller
                                control={control}
                                name="beloeb"

                                render={({ field: { onChange, onBlur, value, ref } }) =>
                                    <Text
                                        label="Beløb"
                                        defaultV={beloeb}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        selected={value}
                                    />
                                }
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        {
                            error ? errorMessage : message
                        }
                        <div className="tc">
                            <CustomizedButtons type="submit" label="Gem" disabled={isLoading} onClick={
                                handleOpen
                            } />
                            {isLoading && <div className="loader">
                                <div className="outer"></div>
                                <div className="middle"></div>
                                <div className="inner"></div>
                            </div>
                            }
                        </div>
                    </form>
                </Box>
            </Modal>
        </React.Fragment>
    );
}