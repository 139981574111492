import React from "react";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from "@mui/material/OutlinedInput";
import { FormHelperText } from "@mui/material";

export default function Selector({ liste, label, onChange, helper }) {

  const tekst = (e) => {
    onChange(e.target.value)
  }

  return (
    <FormControl sx={{ m: 1, width: "30ch" }} variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        native
        label={label}
        onChange={tekst}
        input={<OutlinedInput label={label} />}
      ><option value="">
          <em>None</em>
        </option>
        {liste?.map((b, i) => {
          const navn = b === undefined ? "ingen lister" : b.navn;
          const value = !b.value ? b.navn : b.value
          return (
            <option key={i} value={value}>
              {navn}
            </option>
          )
        })}
      </Select>
      <FormHelperText>{helper}</FormHelperText>
    </FormControl>
  );
}