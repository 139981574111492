import React, { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
//import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";

/*const CssTextField = makeStyles((theme) => ({
  root: {
    "& $notchedOutline": {
      borderColor: "purple",
      borderRadius: 10,
      borderWidth: 2
    },
    "&:hover $notchedOutline": {
      borderColor: "yellow"
    },
    "&$focused $notchedOutline": {
      borderColor: "green"
    }
  },
  focused: {},
  notchedOutline: {}
}));*/

export default function Password({ id, placeholder, err, onChange }) {
  //const outlinedInputClasses = CssTextField();

  const [values, setValues] = useState({
    showPassword: false
  });

  /*const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };*/

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl sx={{ m: 1, width: "30ch" }} variant="outlined">
      <InputLabel>{placeholder}</InputLabel>
      <OutlinedInput
        id={id}
        error={err}
        type={values.showPassword ? "text" : "password"}
        onChange={(e) => onChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        //classes={outlinedInputClasses}
        label={placeholder}
        sx={{
          borderColor: "purple",
          borderRadius: 10,
          borderWidth: 2,
          "&:hover $notchedOutline": {
            borderColor: "yellow"
          },
          "&$focused $notchedOutline": {
            borderColor: "green"
          },
          focused: {},
          notchedOutline: {}
        }}
      />
    </FormControl>
  );
}