import { BehaviorSubject } from 'rxjs';
import url from '../helpers/config';
import axios from 'axios';
const currentUserSubject = new BehaviorSubject((localStorage.getItem('currentUser')));

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() { return currentUserSubject.value }
};


async function login(data, setStatus) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json' }
  };
  let res;
  await axios.post(url.baseURL + `login`, data, { requestOptions }).then(response => {
    res = response.data;
    localStorage.setItem('currentUser', res);
    currentUserSubject.next(res);
    //console.log(res)
    return res;
  }).catch(function (error) {
    if (error.response) {
      res = error.response.data;
      setStatus(res)
      return res;
    } else if (error.request) {
      return error.request
    } else {
      res = error.message
    }
  });
}

function logout() {
  localStorage.removeItem('currentUser');
  currentUserSubject.next(null);
}