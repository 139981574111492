import React, { useState, useEffect } from 'react';
import { authenticationService } from '../services/authentication.service';
import Home from '../Pages/Home/Home';
import { Role } from '../helpers/role'
import NoPage from '../Pages/NoPage/noPage';
import Regnskab from '../Pages/Regnskab/Regnskab';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import '../index2.css';
import {
  Route,
  Routes
} from "react-router-dom";
import NavBar from '../Pages/menus/NavBar';
import { Box, Toolbar } from '@mui/material';
import Desktop from '../Pages/Desktop/Desktop';
import Gaester from '../Pages/gaester/gaester';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(null);
  const [role, setRole] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [name, setName] = useState(null);

  const theme = createTheme({
    typography: {
      h1: {
        color: 'white'
      }
    },
  });

  useEffect(() => {
    let cancel = false;
    authenticationService.currentUser.subscribe(z => {
      if (cancel) return;
      if (z === null) {
        setLoggedIn(false)
        setCurrentUser(null);
        setName(null);
        setRole(false);
      } else {
        setCurrentUser(z);
        setToken(z.token)
        setLoggedIn(true);
        setName(z.navn);
        z.role === Role.VIP ? setRole('VIP') : setRole('pis')
      }
    });
    return () => {
      cancel = true;
    }

  }, [])

  useEffect(() => {
    const handleTapClosing = () => {
      authenticationService.logout();
      setLoggedIn(false)
    }
    window.addEventListener('unload', handleTapClosing)
    return () => {
      window.removeEventListener('unload', handleTapClosing)
    }
  })

  const navn = name ? 'Hej ' + name : 'Rosemarie og brians bryllup'

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <NavBar role={role} loggedIn={loggedIn} navn={navn} user={currentUser} />
        <Toolbar />
        <Box>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path='lister' element={<Desktop token={token} />} />
            <Route path="regnskab" element={<Regnskab token={token} />} />
            <Route path="gaester" element={<Gaester token={token} />} />
            <Route path='*' element={<NoPage />} />
          </Routes>
        </Box>
      </div>
    </ThemeProvider>
  );
}

export default App;