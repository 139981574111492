import axios from 'axios';
import config from '../helpers/config'

const slet = async (id, token, setError, setErrorMessage, sti) => {
    let res;
    //console.log(data)
    const hent = await axios({
        url: config.baseURL + sti + id,
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }).catch(function (error) {
        if (error.response) {
            res = error.response.data;
            setErrorMessage(res)
            setError(true)
            return res;
        } else if (error.request) {
            res = error.request
            setError(true)
            setErrorMessage(res)
            return res
        } else {
            res = error.message
            setError(true)
            setErrorMessage(res)
            return res
        }
    })
    //console.log(hent.data, 'test 2')
    //setListe(hent.data)
    return hent.data
}

export default slet;