import React, { useState } from 'react';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import ButtonIcon from './ButtonIcon';
import Text from './InputTextField';
import CustomizedButtons from './ButtonNoBackdrop';
import Modal from './modal';
import { Box, Chip } from '@mui/material';
import Selector from './Select';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { css } from "@emotion/react";
import PacmanLoader from 'react-spinners/PacmanLoader'
import { opretListe } from '../services';
import { FaClipboardList } from 'react-icons/fa'




const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


/*const columns = [{
    'columnType':
        [{ 'navn': 'Tekst', 'value': 'bogstav' }, { 'navn': 'Tal', 'value': 'tal' }, { 'navn': 'Checkbox', 'value': 'check' }]
}, {
    'columnLength':
        [{ 'navn': 'lille (enkelt ord)', 'value': 10 }, { 'navn': 'Mellem (normal)', 'value': 150 }, { 'navn': 'Stor (F.eks.: beskrivelser)', 'value': 255 }]
}]*/

export default function Opret({ open, handleOpen, token, length }) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [message, setMessage] = useState('')
    const defaultValues = {
        name: '',
        liste: [{ type: "bogstav", navn: "", length: 0, tabel_id: length }]
    }
    const { handleSubmit, formState: { errors }, control } = useForm({
        defaultValues
    });

    const { fields, remove, append } = useFieldArray({
        control,
        name: "liste"
    });

    const columnType = [{ 'navn': 'Tekst', 'value': 'bogstav' }, { 'navn': 'Tal', 'value': 'tal' }, { 'navn': 'Checkbox', 'value': 'check' }]
    const columnLength = [{ 'navn': 'lille (enkelt ord)', 'value': 10 }, { 'navn': 'Mellem (normal)', 'value': 150 }, { 'navn': 'Stor (F.eks.: beskrivelser)', 'value': 255 }]


    const onSubmit = (data) => {
        let cancel = false
        setIsLoading(true)
        const opret = async () => {
            if (!cancel) {
                await opretListe(data, 'nyliste', setMessage, token, setError, setErrorMessage)//ok
                const column = { ...data, name: 'columnnames' }
                await opretListe(column, 'egenliste', setMessage, token, setError, setErrorMessage)
                const tabelnavn = { name: 'tablenames', liste: [{ navn: data.name, tabel_id: length }] }
                await opretListe(tabelnavn, 'egenliste', setMessage, token, setError, setErrorMessage)
                setIsLoading(false)

            }
        }
        opret()
        return () => {
            cancel = true;
        }
    }

    return (

        <Box>
            <ButtonIcon type="button" onClick={handleOpen}>
                <FaClipboardList />
            </ButtonIcon>

            <Modal titel='Opret Liste' open={open} handleOpen={handleOpen} >
                {isLoading ? <PacmanLoader color='blue' css={override} loading={isLoading} size={50} /> : (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'left',
                            }}>
                                <Chip label='Navnet på din liste' color='info' sx={{ width: '150px', ml: 1 }} />
                                <Controller
                                    control={control}
                                    name="name"
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <Text
                                            label="Navnet på din liste"
                                            errors={errors.email}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                        />
                                    }
                                    rules={{ required: true }}

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            {fields.map((item, index) => {
                                return (
                                    <Box key={item.id} sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        position: 'relative'
                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'left',
                                        }}>
                                            <Chip label='kolonne type (tal/tekst/checkbox)' color='info' sx={{ width: '225px', ml: 1 }} />
                                            <Controller
                                                render={({ field: { onChange } }) => <Selector onChange={onChange} liste={columnType} />}
                                                name={`liste.${index}.type`}
                                                control={control}
                                            /></Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'left',
                                        }}>
                                            <Chip label='Navnet på kolonnen' color='info' sx={{ width: '150px', ml: 1 }} />
                                            <Controller
                                                control={control}
                                                name={`liste.${index}.navn`}
                                                render={({ field: { onChange, onBlur } }) =>
                                                    <Text
                                                        label="Navnet på kolonnen"
                                                        errors={errors.email}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                    />
                                                }
                                                rules={{ required: true }}

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            /></Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'left',
                                            }}>
                                            <Chip label='længden af teksten' color='info' sx={{ width: '150px', ml: 1 }} />
                                            <Controller
                                                render={({ field: { onChange } }) => <Selector onChange={onChange} liste={columnLength} helper='kun til tekst' />}
                                                name={`liste.${index}.length`}
                                                control={control}
                                                defaultValue={10}
                                            />

                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                            }}>
                                            <ButtonIcon type="button" onClick={() => remove(index)}>
                                                <RemoveCircleIcon color="secondary" fontSize='large' />
                                            </ButtonIcon>
                                        </Box>
                                    </Box>
                                );
                            })}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}>
                                <ButtonIcon
                                    type="button"
                                    onClick={() => {
                                        append({ type: "bogstav", navn: "", length: 0 });
                                    }}
                                >
                                    <AddCircleIcon color="success" fontSize='large' />
                                </ButtonIcon>
                                <CustomizedButtons type="submit" label="Opret Tabel" />
                            </Box>
                            <label>{error ? errorMessage : message}</label>
                        </form>
                    </Box>)}
            </Modal>
        </Box>
    );
}