import React, { useEffect, useState } from 'react';
import { hentListe } from '../../services';
import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const columns = [
    { id: 'navn', label: 'Navn', minWidth: 170 },
    { id: 'overnatning', label: 'Overnatning', minWidth: 100, align: 'center' },
    {
        id: 'kommer',
        label: 'Antal\u00a0der\u00a0kommer',
        minWidth: 50,
        align: 'right',
    },
    {
        id: 'antal_invi',
        label: 'Antal\u00a0inviterede',
        minWidth: 50,
        align: 'right'
    }
];

const Gaester = ({ token }) => {
    const [liste, setListe] = useState([])
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [length, setLength] = useState(false)
    const [nyListe, setNyListe] = useState('')
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(!openModal);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        let cancel = false;
        setLoading(true)
        const getList = async () => {
            if (cancel) return;
            await hentListe('guests', setListe, token, setError, setErrorMessage)
            setLoading(false)
            setLength(liste?.length === undefined ? false : liste?.length)
            console.log(liste.length)
        }
        getList()
        return () => {
            cancel = true;
        }
    }, [status, token, liste])

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!length ? 'Der er ikke noget i listen' : (
                            liste
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                }))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

export default Gaester;
