import { useState } from 'react';

const useModal = () => {
    const [selectedID, setSelectedID] = useState();
    const [selectedTilID, setSelectedTilID] = useState();
    const [selectedNavn, setSelectedNavn] = useState();
    const [selectedValue, setSelectedValue] = useState();
    const [isShowing, setIsShowing] = useState(false);

    const open = (id, navn, beloeb, tilId) => {
        console.log(id, navn, beloeb, 'useModal')
        setSelectedNavn(navn)
        setSelectedValue(beloeb)
        setSelectedID(id);
        setSelectedTilID(tilId)
        setIsShowing(true);
    };

    const close = () => {
        setSelectedTilID(null)
        setSelectedNavn(null)
        setSelectedValue(null)
        setSelectedID(null);
        setIsShowing(false);
    };

    return {
        isShowing,
        open,
        close,
        selectedID,
        selectedTilID,
        selectedNavn,
        selectedValue
    };
};

export default useModal;