import axios from 'axios';
import config from '../helpers/config'

const opretListe = async (data, link, setMessage, token, setError, setErrorMessage) => {
    let res;
    //console.log(data)
    const opret = await axios({
        url: config.baseURL + link,
        data: data,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }).catch(function (error) {
        if (error.response) {
            res = error.response.data;
            setErrorMessage(res)
            setError(true)
            return res;
        } else if (error.request) {
            res = error.request
            setError(true)
            setErrorMessage(res)
            return res
        } else {
            res = error.message
            setError(true)
            setErrorMessage(res)
            return res
        }
    })
    //console.log(opret.data, 'test 2')
    setMessage(opret.data)
    return opret.data
}

export default opretListe;