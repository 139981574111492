import { Box } from '@mui/material';
import React from 'react';

import Countdown from '../../components/countdown';

const Home = () => {

  return (
    <div>
      <Box
        sx={{
          color: 'white'
        }}
      >
        <h1 className='countdown'>Rosemarie og Brians Bryllup</h1></Box>

      <Countdown timeTillDate="18/03-2023, 14:00" timeFormat="DD/MM-YYYY, hh:mm" titel='Bryllupet' />
      <Countdown timeTillDate="18/03-2023, 17:00" timeFormat="DD/MM-YYYY, hh:mm" titel='Festen' />
    </div>
  );
}

export default Home;
